<template>
    <div class="page-box">
        <div class="left-box">
            <img src="../assets/play.png" @click="toPlayView"
                style="position:relative ;top:10%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/library.png" @click="toSongView"
                style="position:relative ;top:20%;max-width: 70%;height: auto;cursor:pointer">
            <img src="../assets/singer.png" @click="toSingerView"
                style="position:relative ; top:30%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/upload.png" @click="toUploadView"
                style="position:relative ; top:40%;max-width: 70%;height: auto; cursor:pointer">
        </div>

        <div>
            <div style="position:absolute;left:12%;top:6%; background-color: #cbebe34f;width:700px;height:12%;
            border-radius: 45px; font-size:45px; color:rgb(240, 251, 255); user-select: none; 
            box-shadow: 2px 2px 10px 2px #0e6650, inset 2px 2px 10px 2px #0e6650;display:flex ">
                <img :src="this.singer.img"
                    style="position:relative;left:2%;top:10%;width:80px;height:80px;border-radius: 50%">
                <P style="position:relative;left:5%;top:-25%;"> {{ this.singer.pname }}</P>

                <p style="font-size:25px;position:absolute;right:20%;top:25%">总共有{{ this.total }}首歌</p>
                <i class="el-icon-edit" @click="dialogVisible = true, saveTemp()" style="font-size:20px;position:absolute;
                right:5%;top:60%;cursor:pointer;">编辑</i>

                <el-dialog title="编辑信息" :visible.sync="dialogVisible">
                    <el-form :model="singer">
                        <el-form-item label="姓名:" label-width="50px">
                            <!-- autocomplete="off"不记录之前提交表单的信息 -->
                            <el-input v-model="singer.pname" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-radio v-model="radio" label="1">图片地址</el-radio>
                        <el-radio v-model="radio" label="2">上传</el-radio>
                    </el-form>
                    <el-input placeholder="请输入内容" v-model="imgUrl" clearable v-if="this.radio == 1">
                    </el-input>
                    <el-upload v-if="this.radio == 2" class="upload-demo" action="" :http-request="upload" :limit="1"
                        :before-upload="beforeAvatarUpload" :on-preview="handlePreview" :on-remove="handleRemove"
                        :file-list="fileList" list-type="picture">
                        <el-button size="small" type="primary">点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件,且不超过1Mb</div>
                    </el-upload>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogVisible = false, returnTemp()">取 消</el-button>
                        <el-button type="primary" @click="updatePlayer(), dialogVisible = false">确 定</el-button>
                    </div>
                </el-dialog>
            </div>

            <div style="position:absolute;right:5%;top:8%; background-color: #12e87d;width:150px;height:8%;
            border-radius: 45px;  color:rgb(240, 251, 255); user-select: none; 
            box-shadow: 2px 2px 10px 5px #0bab83, inset 1px 1px 10px 5px #14dbaa; ">
                <i class=el-icon-video-play
                    style="position:relative;cursor:pointer;font-size:x-large;top:30%;right:3%">播放全部</i>
            </div>

            <div class="middle-box">
                <!-- 设置表格,包括表格字体颜色和背景透明 -->
                <el-table :data="song" highlight-current-row style="width: 98%"
                    :header-cell-style="{ background: 'transparent', fontWeight: '700', color: '#19f0a8' }" max-height="520"
                    height="520" :row-class-name="tableRowClassName">
                    <!-- 下面这行可以设置序号 -->
                    <el-table-column type="index" :index="indexMethod">
                    </el-table-column>
                    <el-table-column prop="sname" label="歌名">
                    </el-table-column>
                    <el-table-column prop="pname" label="歌手">
                    </el-table-column>
                    <el-table-column prop="time" label="时长">
                    </el-table-column>
                </el-table>
                <br>
                <!-- 分页模块 -->
                <el-pagination hide-on-single-page layout="prev, pager, next" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :total="total"></el-pagination>
                <br>

            </div>
        </div>


        <div>
            <!-- 设置id名date,后面的定时器方法中document.getElementById("date")可以把文本填入, 显示时间,文本不可选中 -->
            <p id="date" style="position:absolute; top:o; right:10% ; font-weight:bold ; user-select: none;"></p>
            <!-- 退出按钮,移入变样式 -->
            <p style="position:absolute; top:o; right:5% ; font-weight:bold ;cursor:pointer;" @click="exit"
                onmouseout="this.style.color='';" onmouseover="this.style.color='#ff0000'; this.style.cursor='hand'">退出</p>
        </div>



    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {



            // 歌曲名称
            song: [],
            total: 0,
            // 右上角时间定时器
            timer: '',
            // 分页记录当前编号
            pagination: {
                currentPage: 1,  //当前页
                pageSize: 10  //每页显示多少条数据
            },
            singer: '',
            name: this.$route.query.name,
            // 表格是否显示
            dialogVisible: false,
            // 姓名备份
            nameTemp: '',
            // 图片地址备份
            imgTemp: '',
            // 单选框
            radio: '1',
            // 要上传图片的地址
            imgUrl: '',
            fileList: [],
            


        };
    },
    methods: {

        beforeAvatarUpload(file) {
            const isJPG =
                file.type === "image/jpg" ||
                file.type === "image/jpeg" ||
                file.type === "image/png";
            const isLt2M = file.size / (1024 * 1024) < 1;
            if (!isJPG) {
                this.$message.error("仅支持jpeg,jpg,png格式的图片！");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 1MB!");
            }
            return isJPG && isLt2M;
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },

        // 表格斑马纹样式修改
        tableRowClassName({ rowIndex }) {
            if (rowIndex % 2 == 1) {
                return "warning-row";
            } else {
                return "success-row";

            }
        },

        // 搜索功能空格输入
        toFilterData() {
            // 当用户没有输入内容就按enter键时，就return，不做任何操作，不去搜索
            this.search();

        },


        // 表格序号
        indexMethod(index) {
            return index + (this.pagination.currentPage - 1) * this.pagination.pageSize + 1
        },

        onsubmit: function () {
            alert("查询数据");
        },
        handleSizeChange: function (val) {
            alert("每页记录数变化:" + val)
        },
        handleCurrentChange: function (val) {
            // 把当前页赋值给currentPage
            this.pagination.currentPage = val
            axios.get("https://music-box.top:8443/songs/bySinger", {
                params: {
                    pageSize: 10,
                    page: val,
                    // name: this.$route.query.name
                    name: sessionStorage.getItem('testKey')
                },
                headers: { Token: localStorage.getItem('token') }
            }).then(result => {
                this.song = result.data.data.rows
                this.total = result.data.data.total

            }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });

        },


        myDate() {
            var now = new Date();
            var year = now.getFullYear();
            var month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : "0" + (now.getMonth() + 1);
            var day = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
            var hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
            var minutes = now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
            var seconds = now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds();
            document.getElementById("date").innerHTML =
                year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds;
        },

        exit() {
            localStorage.setItem("token", "");
            this.$router.push({ name: 'login' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },



        // 路由跳转
        toPlayView() {
            this.$router.push({ name: 'play' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        // 路由跳转
        toSingerView() {
            this.$router.push({ name: 'singer' })  //这个方式通过index.js设置name后,可以后退,也可以带参数

        },

        // 跳转曲库
        toSongView() {
            this.$router.push({ name: 'song' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        // 跳转更新页面
        toUploadView() {
            this.$router.push({ name: 'upload' })  //这个方式通过index.js设置name后,可以后退,也可以带参数

        },

        saveTemp() {
            this.nameTemp = this.singer.pname;
            this.imgTemp = this.singer.img;

        },
        returnTemp() {
            this.singer.pname = this.nameTemp;
            this.singer.img = this.imgTemp;
        },


        // 更新歌手信息
        updatePlayer() {
            axios.put("https://music-box.top:8443/singer", {
                id: this.singer.id,
                pname: this.singer.pname,
                img: this.imgUrl},
                {headers: { Token: localStorage.getItem('token') }

            }).then(result => {
                if (result.data.code == 1) {
                    this.$message('更新成功');
                } else {
                    this.singer.pname = this.nameTemp;
                    this.singer.img = this.imgTemp;
                    this.$message('更新失败');
                }
            }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });
        },

        // 上传图片
        upload(param) {
            const formData = new FormData()
            formData.append('file', param.file)
            axios.post("https://music-box.top:8443/uploadImg", formData,{
            headers:{Token:localStorage.getItem('token')}
            }
            ).then(result => {
                if (result.data.code == 1) {

                    this.imgUrl = result.data.data
                    this.$message('上传成功');
                } else {
                    this.$message('上传失败');

                }
            }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });
        }



    },



    mounted() {

        axios.get("https://music-box.top:8443/songs/bySinger", {
            params: {
                name: sessionStorage.getItem('nameKey')
            },
            headers:{Token:localStorage.getItem('token')}
        }).then(result => {
            this.song = result.data.data.rows
            this.total = result.data.data.total
        });
        // 拼接url 去访问该歌手的信息
        axios.get("https://music-box.top:8443/singer/" + this.name, {
            headers:{Token:localStorage.getItem('token')}
        }).then(result => {
            this.singer = result.data.data.rows[0]
        }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });

        this.timer = setInterval(this.myDate, 100)

    },

    beforeDestroy() {
        clearInterval(this.timer);
    },


}




</script>

<style scoped  >
/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
    background-color: transparent;
    border-radius: 30px;
    margin-left: 1%;
    margin-right: 1%;
    border: none
}


/* 表头内背景颜色 */
::v-deep .el-table th {

    background-color: transparent;

}

/* 表格内背景颜色*/
::v-deep .el-table tr {

    background-color: transparent;

}

/* 表格数据背景透明,表格横线去掉*/
::v-deep .el-table td {
    background-color: transparent;
    border-bottom: none !important;

}

/* 去掉表格最下面一条线*/
::v-deep .el-table::before {

    height: 0;

}

/* 表格悬停的时候变色*/
::v-deep .el-table__body tr:hover>td {
    background-color: #0cdd6a !important;
    box-shadow: 1px -1px 50px 1px #6df876, inset 0px 0px 50px 1px #6df876;
}

/* 选中表格的颜色*/
::v-deep .el-table__body tr.current-row>td {
    color: #010603;
    background: #0cf073b8 !important;
}

/* 斑马纹*/
::v-deep .el-table__row.warning-row {
    background: hsl(131, 61%, 75%);
}

::v-deep .el-table .success-row {
    background: #98d7ee;
}


/*  分页未选中按钮背景颜色*/
::v-deep .el-pagination .is-background {
    background-color: transparent;

}

/* 分页未选中的字体颜色 */
::v-deep .el-pagination .el-pager li:not(.disabled) {
    background-color: transparent;

    color: #04572d;
    font-size: 18px;
}

/*  分页选中按钮背景颜色和字体颜色 */
::v-deep.el-pagination .el-pager li:not(.disabled).active {
    background-color: transparent;
    color: #19f0a8;
    font-size: 17px;
}

/* 上一页和下一页背景颜色和字体颜色*/
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
    background-color: rgba(99, 135, 206, 0);
    color: #04572d;
    transform: scale(1.5)
}





/*   上面为修改elementUI样式 , 下面为模块样式* /


/* 最外的div*/
.page-box {
    background-color: #26AE85;
    display: flex;
    height: 100vh;
}

/* 中间层的div */
.middle-box {
    position: absolute;
    left: 12%;
    top: 20%;
    height: 75vh;
    width: 85%;
    background-color: #1791734f;
    border-radius: 45px;
    box-shadow: 2px 2px 10px 2px #0e6650, inset 2px 2px 10px 2px #0e6650;
    user-select: none;

}

/* 左边模块样式 */

.left-box {
    height: 85%;
    width: 7%;
    position: relative;
    top: 7%;
    left: 2%;

    /* box-shadow: inset 是设为内阴影,默认是外阴影,剩下五个值,分别为水平,竖直,模糊效果,阴影半径,颜色 */
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
    /* 高斯模糊*/
    border-radius: 45px;
    /* 圆角*/

    overflow-y: auto;
    user-select: none;



}

/* 掠过左边按钮的转换时间 */
.left-box:hover {
    transition: all 2s;
    /*旋转时间 */
    transform: scale(1.05);
}

/* 掠过左边按钮的图片具体变化*/
.left-box>img:hover {
    transition: all 1.5s;
    /*旋转时间 */
    transform: scale(1.2);
    /* 放大1.1倍,旋转360度*/
    filter: brightness(1.2);
    /* 提升亮度 */
}




/* 去除手机网页点击事件的阴影 */
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}
</style>



