<template>
    <div class="page-box">
        <div class="left-box">
            <img src="../assets/play.png" @click="toPlayView"
                style="position:relative ;top:10%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/library.png" @click="toSongView"
                style="position:relative ;top:20%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/singer.png"
                style="position:relative ; top:30%;max-width: 70%;height: auto; filter:brightness(150%);cursor:pointer">
            <img src="../assets/upload.png" @click="toUploadView"
                style="position:relative ; top:40%;max-width: 70%;height: auto; cursor:pointer">
        </div>



        <div>
            <!-- 设置id名date,后面的定时器方法中document.getElementById("date")可以把文本填入, 显示时间,文本不可选中 -->
            <p id="date" style="position:absolute; top:o; right:10% ; font-weight:bold ; user-select: none;"></p>
            <!-- 退出按钮,移入变样式 -->
            <p style="position:absolute; top:o; right:5% ; font-weight:bold ;cursor:pointer;user-select: none;" @click="exit"
                onmouseout="this.style.color='';" onmouseover="this.style.color='#ff0000'; this.style.cursor='hand'">退出</p>
        </div>

        <div class="main-page">
            <div style="position:absolute;left:40%;top:8%; background-color: #3ce6bca8;
            border-radius: 45px;font-size:45px; color:rgb(245, 247, 248); user-select: none; filter: blur(10px);
            box-shadow: 1px 1px 5px 5px #0e665000, inset 1px 1px 5px 5px #0e665000;">
                一一一一一一一一
            </div>
            <div style="position:absolute;left:47%;top:8%; font-weight:bold;font-size:45px;user-select:none">歌手列表
            </div>

            <div style="display:flex;position:absolute;left:20%; top:17%;user-select:none;color:azure">
                <P style="margin-left: 0px;margin-right:50px;width:60px">索引:</P>
                <P style="margin-left: 50px;margin-right:50px ;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(1)">A~E</P>
                <P style="margin-left: 50px;margin-right:50px;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(2)">F~J</P>
                <P style="margin-left: 50px;margin-right:50px;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(3)">K~O</P>
                <P style="margin-left: 50px;margin-right:50px;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(4)">P~T</P>
                <P style="margin-left: 50px;margin-right:50px;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(5)">U~Z</P>
                <P style="margin-left: 50px;margin-right:50px;cursor:pointer" onmouseout="this.style.color='';"
                    onmouseover="this.style.color='#19f0a8'" @click="findByPrefix(0)">All</P>
            </div>



            <div>
                <el-row style="position:absolute; left:25%; top:25%; width:60%;user-select:none;">
                    <el-col :span="7" v-for="(item) in singer" :key="item.pname">
                        <el-card :body-style="{ padding: '0px' }" style="margin-right:40px ; margin-bottom:60px;width:80% ;
                         background-color:rgba(0, 255, 255, 0); border:none;border-radius:50%;overflow: visible;
                      box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
                      " v-if="item">
                            <!-- 加了一层判断 v-if="singer[index]" 如果有数据才渲染 -->
                            <img :src="item.img" class="image" @click="toOneSingerView(item)" style="cursor:pointer">
                            <div style="padding: 5px;">
                                <br>
                                <span @click="toOneSingerView(item)" onmouseout="this.style.color='';"
                                    onmouseover="this.style.color='#19f0a8'" style="cursor:pointer">{{ item.pname }}</span>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <div class="page" style="position:absolute;left:46.5%;top:90%">
                    <el-pagination small layout="prev, pager, next" @size-change="handleSizeChange"
                        @current-change="handleCurrentChange" :total="total" :page-size="6"></el-pagination>
                </div>

            </div>
        </div>



    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {

            currentDate: new Date(),

            total: 0,
            // 右上角时间定时器
            timer: '',

            // 分页记录当前编号
            pagination: {
                currentPage: 1,  //当前页
                pageSize: 6  //每页显示多少条数据
            },

            // 歌手列表信息
            singer: [],
        };
    },

    methods: {

        onsubmit: function () {
            alert("查询数据");
        },
        handleSizeChange: function (val) {
            alert("每页记录数变化:" + val)
        },
        handleCurrentChange: function (val) {
            // 把当前页赋值给currentPage
            this.pagination.currentPage = val
            axios.get("https://music-box.top:8443/singer", {
                params: {
                    pageSize: 6,
                    page: val,

                },
                headers: { Token: localStorage.getItem('token') }
            }).then(result => {
                this.singer = result.data.data.rows
                this.total = result.data.data.total

            }).catch((error) => {
                // 请求失败处理逻辑
                localStorage.setItem("token", "");
                if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });

        },


        myDate() {
            var now = new Date();
            var year = now.getFullYear();
            var month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : "0" + (now.getMonth() + 1);
            var day = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
            var hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
            var minutes = now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
            var seconds = now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds();
            document.getElementById("date").innerHTML =
                year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds;
        },

        exit() {
            localStorage.setItem("token", "");
            this.$router.push({ name: 'login' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },



        toPlayView() {
            this.$router.push({ name: 'play' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toSongView() {
            this.$router.push({ name: 'song' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toOneSingerView(val) {
            sessionStorage.setItem('nameKey',val.pname); 
            this.$router.push(
                {
                    name: 'songs',
                    query: {
                        name: val.pname
                    },
                    // 通过params,传不可见参数
                    params: {
                        url: val.img
                    }
                }
            )
        },
        toUploadView() {
            this.$router.push({ name: 'upload' })  //这个方式通过index.js设置name后,可以后退,也可以带参数

        },

        // 通过前缀搜索歌手
        findByPrefix(val) {
            axios.get("https://music-box.top:8443/singer/prefix/" + val, {
                params: {
                    pageSize: 6,
                },
                headers: { Token: localStorage.getItem('token') }
            }).then(result => {
                this.singer = result.data.data.rows
                this.total = result.data.data.total
            }).catch((error) => {
                // 请求失败处理逻辑
                localStorage.setItem("token", "");
                if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });
        }



    },



    // 钩子方法
    mounted() {
        axios.get("https://music-box.top:8443/singer", {
            params: {
                pageSize: 6,
            },
            headers: { Token: localStorage.getItem('token') }
        }).then(result => {
            this.singer = result.data.data.rows
            this.total = result.data.data.total
        }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });

        this.timer = setInterval(this.myDate, 100)


    },

    beforeDestroy() {
        clearInterval(this.timer);
    }


};




</script>

<style scoped  >
.image {
    position: relative;
    top: 5%;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: none;
}



/*  分页未选中按钮背景颜色*/
::v-deep .el-pagination .is-background {
    background-color: transparent;

}

/* 分页未选中的字体颜色 */
::v-deep .el-pagination .el-pager li:not(.disabled) {
    background-color: transparent;

    color: #04572d;
    font-size: 18px;
}

/*  分页选中按钮背景颜色和字体颜色 */
::v-deep.el-pagination .el-pager li:not(.disabled).active {
    background-color: transparent;
    color: #19f0a8;
    font-size: 17px;
}

/* 上一页和下一页背景颜色和字体颜色*/
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
    background-color: rgba(99, 135, 206, 0);
    color: #04572d;
    transform: scale(1.5)
}



/*   上面为修改elementUI样式 , 下面为模块样式* /


/* 最外的div*/
.page-box {
    background-color: #26AE85;
    display: flex;
    height: 100vh;
}

/* 左边模块样式 */

.left-box {
    height: 85%;
    width: 7%;
    position: relative;
    top: 7%;
    left: 2%;

    /* box-shadow: inset 是设为内阴影,默认是外阴影,剩下五个值,分别为水平,竖直,模糊效果,阴影半径,颜色 */
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
    /* 高斯模糊*/
    border-radius: 45px;
    /* 圆角*/

    overflow-y: auto;
    user-select: none;



}

/* 掠过左边按钮的转换时间 */
.left-box:hover {
    transition: all 2s;
    /*旋转时间 */
    transform: scale(1.05);
}

/* 掠过左边按钮的图片具体变化*/
.left-box>img:hover {
    transition: all 1.5s;
    /*旋转时间 */
    transform: scale(1.2);
    /* 放大1.1倍,旋转360度*/
    filter: brightness(1.2);
    /* 提升亮度 */
}




/* 去除手机网页点击事件的阴影 */
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}
</style>



