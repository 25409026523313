<template>
    <div class="page-box">
        <div class="left-box">
            <img src="../assets/play.png" @click="toPlayView"
                style="position:relative ;top:10%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/library.png" @click="toSongView"
                style="position:relative ;top:20%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/singer.png" @click="toSingerView"
                style="position:relative ; top:30%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/upload.png"
                style="position:relative ; top:40%;max-width: 70%;height: auto;filter:brightness(150%) ;cursor:pointer">
        </div>


        <div>
            <!-- 设置id名date,后面的定时器方法中document.getElementById("date")可以把文本填入, 显示时间,文本不可选中 -->
            <p id="date" style="position:absolute; top:o; right:10% ; font-weight:bold ; user-select: none;"></p>
            <!-- 退出按钮,移入变样式 -->
            <p style="position:absolute; top:o; right:5% ; font-weight:bold ;cursor:pointer;user-select: none;" @click="exit"
                onmouseout="this.style.color='';" onmouseover="this.style.color='#ff0000'; this.style.cursor='hand'">退出</p>
        </div>

        <div class="main-page">

            <div style="position:absolute; left:40%;top:5%; font-weight:bold; background-color:rgba(166, 235, 235, 0.961);
            box-shadow:0px 0px 1px 1px rgba(0, 255, 76, 0.329),inset 0px 0px 1px 1px rgb(0, 255, 76, 0.329);border-radius:45px;
            font-size:45px;user-select:none">&nbsp;&nbsp;上传页面&nbsp;&nbsp;
            </div>
            <div style="position:absolute;left:38%;top:15%;font-weight:bold;font-size:45px;user-select:none;width:50%">
                <el-tabs v-model="activeName" type="border-card" :before-leave="beforeLeave">
                    <el-tab-pane label="上传音乐" name="song" style="position:relative;width:400px;height:400px ;margin:0px">
                        <el-form :label-position="labelPosition" label-width="150px" :model="formLabelAlign"
                            style="height:400px">
                            <el-form-item label="歌手">
                                <el-input v-model="formLabelAlign.pname"></el-input>
                            </el-form-item>
                            <el-form-item label="歌名">
                                <el-input v-model="formLabelAlign.sname"></el-input>
                            </el-form-item>
                            <el-form-item label="上传歌曲音源" style="margin:0px">
                                <el-upload class="upload-demo" action="" :http-request="uploadMusic" :limit="1"
                                    :before-upload="beforeAvatarUploadMusic" :on-remove="handleRemoveMusic"
                                    :file-list="fileList" list-type="text">
                                    <el-button size="small" type="primary" style="background-color: rgb(3, 199, 101);"
                                        v-if="musicIf">本地音源</el-button>
                                    <div slot="tip" class="el-upload__tip" style="width:280px" v-if="musicIf">
                                        只能上传mpeg,mp3,wav,ogg文件,且不超过50Mb</div>
                                </el-upload>
                                <!-- 上传状态图标 -->
                                <i class="el-icon-loading" v-if="!musicIf && !songStatus"></i>
                                <i class="el-icon-finished" v-if="!musicIf && songStatus"></i>
                            </el-form-item>
                            <el-form-item label="上传歌曲图片" style="width:400px;height:120px">

                                <el-radio-group v-model="radio1" @change="radioChange">
                                    <el-radio v-model="radio1" label="1">在线网址</el-radio>
                                    <el-radio v-model="radio1" label="2">本地上传</el-radio>
                                </el-radio-group>

                                <el-input placeholder="图片地址URL" v-model="formLabelAlign.songImgUrl" clearable
                                    maxlength="255" v-if="this.radio1 == 1">
                                </el-input>
                                <el-upload v-if="this.radio1 == 2" class="upload-demo" action="" :http-request="uploadImg"
                                    :limit="1" :before-upload="beforeAvatarUploadImg" :on-remove="handleRemoveImg"
                                    :file-list="fileList" list-type="picture">

                                    <el-button size="small" type="primary" v-if="imgIf"
                                        style="background-color: rgb(3, 199, 101);">本地图片</el-button>
                                    <div slot="tip" class="el-upload__tip" v-if="imgIf">只能上传jpg/png文件,且不超过1Mb</div>
                                </el-upload>
                            </el-form-item>
                            <div style="position:relative;left:19%;bottom:-20%;">
                                <el-button type="primary" style="background-color:rgb(3, 199, 101)" :disabled="!songStatus && !imgStatus" @click="addMusic">上传<i
                                        class="el-icon-upload el-icon--right"></i></el-button>
                            </div>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="编辑歌手" name="player">
                        <el-form :label-position="labelPosition" label-width="150px" :model="singerForm"
                            style="height:400px">
                            <el-form-item label="歌手" style="width:400px">
                                <el-input v-model="singerForm.pname"></el-input>
                            </el-form-item>



                            <el-form-item label="修改歌曲图片" style="width:400px;height:120px">

                                <el-radio-group v-model="radio2" @change="radioChange">
                                    <el-radio v-model="radio2" label="1">图片地址</el-radio>
                                    <el-radio v-model="radio2" label="2">上传</el-radio>
                                </el-radio-group>


                                <el-input placeholder="图片地址URL" v-model="singerForm.url" clearable maxlength="255"
                                    v-if="this.radio2 == 1">
                                </el-input>
                                <el-upload v-if="this.radio2 == 2" class="upload-demo" action="" :http-request="uploadImg"
                                    :limit="1" :before-upload="beforeAvatarUploadImg" :on-remove="handleRemoveImg"
                                    :file-list="fileList" list-type="picture">
                                    <el-button size="small" type="primary" v-if="imgIf"
                                        style="background-color: rgb(3, 199, 101);">本地图片</el-button>
                                    <div slot="tip" class="el-upload__tip" v-if="imgIf">只能上传jpg/png文件,且不超过1Mb</div>
                                </el-upload>
                            </el-form-item>
                            <div style="position:relative;left:-12%;bottom:-20%;">
                                <el-button type="primary" style="background-color:rgb(3, 199, 101);" @click="addSinger">提交<i
                                        class="el-icon-upload el-icon--right"></i></el-button>
                            </div>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>

        </div>



    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {

            currentDate: new Date(),

            total: 0,
            // 右上角时间定时器
            timer: '',
            radio1: '1',
            radio2: '1',
            imgIf: true,
            musicIf: true,
            // 标签页名字
            activeName: 'song',
            labelPosition: 'left',
            formLabelAlign: {
                pname: '',
                sname: '',
                songImgUrl: '',
                songUrl: ''
            },
            fileList: [],
            song: [],
            MusicTime: '',
            singerForm: {
                pname: '',
                url: ''
            },
            songStatus: false,
            imgStatus:false,

        };
    },

    methods: {


        myDate() {
            var now = new Date();
            var year = now.getFullYear();
            var month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : "0" + (now.getMonth() + 1);
            var day = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
            var hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
            var minutes = now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
            var seconds = now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds();
            document.getElementById("date").innerHTML =
                year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds;
        },

        exit() {
            localStorage.setItem("token", "");
            this.$router.push({ name: 'login' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },


        toPlayView() {
            this.$router.push({ name: 'play' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toSongView() {
            this.$router.push({ name: 'song' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toSingerView() {
            this.$router.push({ name: 'singer' })  //这个方式通过index.js设置name后,可以后退,也可以带参数

        },

        radioChange(val) {
            if (val == 1) {
                this.imgIf = true;
            }
        },

        beforeLeave() {
            this.imgIf = true
            this.musicIf = true
            this.fileList = []
            return true
        },

        // 上传图片
        uploadImg(param) {
            const formData = new FormData()
            formData.append('file', param.file)
            axios.post("https://music-box.top:8443/uploadImg", formData, {
                headers: { Token: localStorage.getItem('token') }
            }
            ).then(result => {
                if (result.data.code == 1) {

                    this.formLabelAlign.songImgUrl = result.data.data
                    this.$message('图片上传成功');
                    this.imgIf = false;
                    this.imgStatus = true

                } else {

                    this.$message('图片上传失败');
                    this.fileList = [];

                }
            }).catch((error) => {
                // 请求失败处理逻辑
                localStorage.setItem("token", "");
                if (error.response.status == 401) {
                    this.$message(
                        {
                            showClose: true,
                            message: error.response.data.msg,
                            type: 'warning'
                        }
                    )
                } else {
                    this.$message(
                        {
                            showClose: true,
                            message: "请求错误",
                            type: 'warning'
                        }
                    )
                }
                this.$router.push({ name: 'login' })
            });
        },
        beforeAvatarUploadImg(file) {
            const isJPG =
                file.type === "image/jpg" ||
                file.type === "image/jpeg" ||
                file.type === "image/png";
            const isLt2M = file.size / (1024 * 1024) < 1;
            if (!isJPG) {
                this.$message.error("仅支持jpeg,jpg,png格式的图片!");
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 1MB!");
            }
            return isJPG && isLt2M;
        },
        // 上传歌曲
        uploadMusic(param) {
            this.musicIf = false
            const formData = new FormData()
            formData.append('file', param.file)
            axios.post("https://music-box.top:8443/uploadSong", formData, {
                headers: { Token: localStorage.getItem('token') },
            }
            ).then(result => {
                    if (result.data.code == 1) {
                        this.formLabelAlign.songUrl = result.data.data.url
                        this.MusicTime = result.data.data.time
                        this.songStatus=true
                        this.$message('歌曲上传成功');
                    } else {
                        this.$message('歌曲上传失败');
                        this.fileList = [];

                    }
                }).catch((error) => {
                    // 请求失败处理逻辑
                    localStorage.setItem("token", "");
                    if (error.response.status == 401) {
                        this.$message(
                            {
                                showClose: true,
                                message: error.response.data.msg,
                                type: 'warning'
                            }
                        )
                    } else {
                        this.$message(
                            {
                                showClose: true,
                                message: "请求错误",
                                type: 'warning'
                            }
                        )
                    }
                    this.$router.push({ name: 'login' })
                });
    },
    beforeAvatarUploadMusic(file) {
        const isJPG =
            file.type === "audio/mpeg" ||
            file.type === "audio/mp3" ||
            file.type === "audio/wav" ||
            file.type === "audio/ogg";
        const isLt2M = file.size / (1024 * 1024 * 50) < 1;
        if (!isJPG) {
            this.$message.error("仅支持mpeg,mp3,wav,ogg格式的歌曲！");
        }
        if (!isLt2M) {
            this.$message.error("上传歌曲大小不能超过 50MB!");
        }
        return isJPG && isLt2M;
    },

    handleRemoveImg(file, fileList) {
        this.imgIf = true
    },
    handleRemoveMusic(file, fileList) {
        this.musicIf = true
    },

    // 上传音乐
    addMusic() {

        axios.post("https://music-box.top:8443/songs", {
            pname: this.formLabelAlign.pname,
            sname: this.formLabelAlign.sname,
            time: this.MusicTime,
            img: this.formLabelAlign.songImgUrl,
            url: this.formLabelAlign.songUrl,
        },
            {
                headers: { Token: localStorage.getItem('token') }
            }
        ).then(result => {
            if (result.data.code == 1) {
                this.$message('歌曲添加成功');
                this.formLabelAlign.pname = '';
                this.formLabelAlign.sname = '';
                this.formLabelAlign.songImgUrl = '';
                this.formLabelAlign.songUrl = '';
                this.fileList = [];
                this.imgIf = true;
                this.musicIf = true;
                this.songStatus = false;
                this.imgStatus =false;
            } else {
                this.$message('歌曲添加失败');
            }
        }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });
    },

    //编辑歌手信息

    addSinger() {
        axios.post("https://music-box.top:8443/singer", {
            pname: this.singerForm.pname,
            img: this.singerForm.url,
        },
            {
                headers: { Token: localStorage.getItem('token') }
            }
        ).then(result => {
            if (result.data.code == 1) {
                this.$message('信息修改成功');
                this.singerForm.pname = '';
                this.singerForm.url = '';
                this.fileList = [];
                this.imgIf = true;
                this.musicIf = true;
            } else {
                this.$message('信息修改失败');
            }
        }).catch((error) => {
            // 请求失败处理逻辑
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });
    }



},



// 钩子方法
mounted() {
    axios(
        {
            method: "get",
            url: "https://music-box.top:8443/upload",

            headers: { Token: localStorage.getItem('token') }

        }
    ).catch((error) => {
        localStorage.setItem("token", "");
        if (error.response.status == 401) {
            this.$message(
                {
                    showClose: true,
                    message: error.response.data.msg,
                    type: 'warning'
                }
            )
        } else {
            this.$message(
                {
                    showClose: true,
                    message: "请求错误",
                    type: 'warning'
                }
            )
        }
        this.$router.push({ name: 'login' })
    });

    this.timer = setInterval(this.myDate, 100)


},

beforeDestroy() {
    clearInterval(this.timer);
}


};



</script>

<style scoped  >




/*  分页未选中按钮背景颜色*/
::v-deep .el-pagination .is-background {
    background-color: transparent;

}

::v-deep .el-radio__input.is-checked+.el-radio__label {
    color: #0be4c7
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
    background: #0ded63;
    border-color: #64e005;
}



/* 修改标签页的标签栏的背景*/
::v-deep .el-tabs .el-tabs__header {
    padding: 0;
    position: relative;
    margin: 0 0 15px;
    background-color: transparent;
    border: none;

}

::v-deep .el-tabs__content {
    overflow: visible;
    position: relative;
    left: -20%
}


/* 修改标签页下的背景颜色*/
::v-deep .el-tabs--border-card {
    background: #a1090900;
    border: 1px solid #0c3eb100;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .12), 0 0 6px 0 rgba(0, 0, 0, .04);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0), 0 0 6px 0 rgba(0, 0, 0, 0)
}

/* 修改标签页下活动标签的字体*/
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #bfff40;
    background-color: #ffffff00;
    border-right-color: #dcdfe600;
    border-left-color: #dcdfe600;
    font-size: 35px;
    box-shadow: inset 1px 1px 5px 5px rgb(16, 196, 175);
    border-radius: 45%;
}

/* 修改标签页下非活动标签的字体*/
::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item {
    color: #69003d;
    font-size: 25px;
}

/*   上面为修改elementUI样式 , 下面为模块样式* /


/* 最外的div*/
.page-box {
    background-color: #26AE85;
    display: flex;
    height: 100vh;
}

/* 左边模块样式 */

.left-box {
    height: 85%;
    width: 7%;
    position: relative;
    top: 7%;
    left: 2%;

    /* box-shadow: inset 是设为内阴影,默认是外阴影,剩下五个值,分别为水平,竖直,模糊效果,阴影半径,颜色 */
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
    /* 高斯模糊*/
    border-radius: 45px;
    /* 圆角*/

    overflow-y: auto;
    user-select: none;



}

/* 掠过左边按钮的转换时间 */
.left-box:hover {
    transition: all 2s;
    /*旋转时间 */
    transform: scale(1.05);
}

/* 掠过左边按钮的图片具体变化*/
.left-box>img:hover {
    transition: all 1.5s;
    /*旋转时间 */
    transform: scale(1.2);
    /* 放大1.1倍,旋转360度*/
    filter: brightness(1.2);
    /* 提升亮度 */
}

/* 去除手机网页点击事件的阴影 */
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}</style>



