import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "../views/LoginView.vue";
import PlayView from "../views/PlayView.vue"
import SongView from "../views/SongView.vue"
import SingerView from "../views/SingerView.vue"
import OneSingerView from "../views/OneSingerView.vue"
import  UploadView from "../views/UploadView.vue"

Vue.use(VueRouter)

// 缓存原来的 push 方法
const originalPush = VueRouter.prototype.push
//指定新的push方法
VueRouter.prototype.push = function (location, onResolve, onReject) {
  //制定了一个成功或者失败的回调
    if (onResolve || onReject) {
        //直接调用原来的 push 方法
        // originalPush(location, onResolve, onReject)  //这里使用 call 可以直接指定当前的 router 对象，要是没有这个就会默认是window 对象，启用严格模式之后就是 undefined
        return originalPush.call(this, location, onResolve, onReject)
    }
    //如果没有指明一个成功或者失败的回调，需要使用到catch处理
    return originalPush.call(this, location).catch((err) => {
        //如果是重复导航所引起的错误的话
        if (VueRouter.isNavigationFailure(err)) {
            //这个 return err 返回的是成功的 Promise，其中Promise的value是 err
            return err
        }
        //如果是其他的错误就会接着往下传
        return Promise.reject(err)
    })
}


const routes = [
  {
    // 登录页面
    path:'/',
    name:'login',
    component:LoginView
  },
  {
    // 播放页面
    path:'/play',
    name:'play',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
     },
    component:PlayView
  },
  {
    // 歌曲列表
    path:'/songs',
    name:'song',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
     },
    component:SongView
  },
  // 歌手列表
  {
    path:'/singer',
    name:'singer',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
     },
    component:SingerView
  },

  // 通过歌手查找歌曲  ,在路径后面加问好很重要,不然会丢失路径
  {
    // path:'/songs/bySinger/:name?',
    path:'/songs/bySinger/',
    name:'songs',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
     },
    component:OneSingerView,
    
  },
  // 上传页面
  {
    path:'/upload',
    name:'upload',
    meta: {
      requireAuth: true, // 添加该字段，表示进入这个路由是需要登录的
     },
    component:UploadView,

  },
  {
    path:'*',
    component:()=>import('../views/AboutView.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
