<template>
    <div>
        <div class="box">
            <div class="left">
                <img src="../assets/cut1.png" style="position:absolute; right:35% ;top:10%" />
                <P class="title">Welcome</P>
                <p class="title1">to my</p>
                <p class="title2">谬斯博思 ~ ~</p>

            </div>

            <div style="background-color: rgb(254, 255, 255) ;max-width:20%">
                <h1 class="account">登录界面</h1>
                <el-form ref="rulesForm" :rules="formRules" :model="user"
                    style="position:relative;top:30%;left:40%; width:200px">

                    <el-form-item label="用户" prop="loginName">
                        <el-input v-model="user.loginName" placeholder="请输入内容" show-word-limit :clearable="true"></el-input>
                    </el-form-item>

                    <el-form-item label="密码" prop="password">
                        <el-input placeholder="请输入密码" v-model="user.password" show-password prop="password"
                            :clearable="true" @keyup.enter.native="toFilterData"></el-input>
                    </el-form-item>
                    <p></p>
                    <el-row>
                        <el-button type="success" plain style="position:absolute;left:0%;" @click="login()">登录</el-button>
                    </el-row>
                </el-form>

            </div>
        </div>
        <div style="position:absolute ; bottom:1%;left:35%;font-size:x-small">Copyright © 2023 Shelhon. All Rights Reserved. <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023096586号</a></div>
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            user: {
                loginName: "",
                password: ""
            },

            //  表单对应上面数据内容的规则,命名要一直
            formRules: {
                loginName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' },
                    { min: 5, max: 10, message: '用户名长度在 5 到 10 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输密码', trigger: 'blur' },
                    { min: 6, max: 20, message: '密码长度在 6 到 20 个字符', trigger: 'blur' },
                    { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/, message: '字母、数字和标点符号至少包含两种', trigger: 'blur' }
                ]
            },

            msg: ''

        }
    },

    methods: {

        // //查询 
        toFilterData() {
            // 当用户没有输入内容就按enter键时，就return，不做任何操作，不去搜索
            if (this.password === '' || this.loginName === '') {
                return
            } else {
                // 请求查询接口，将列表展现出来
                this.login();
            }
        },

        wrongInput() {
            this.$message(
                {
                    showClose: true,
                    message: this.msg,
                    type: 'warning'
                }
            )
        },


        login() {
            if (this.user.loginName === "" || this.user.password === "") {
                this.msg = "请输入账户密码"
                this.wrongInput()
            } else (
                axios(
                    {
                        method: "post",
                        url: "https://music-box.top:8443/login",
                        data: {
                            loginName: this.user.loginName,
                            password: this.user.password,
                            
                        }
                    }
                ).then(result => {
                    if (result.data.code === 0) {
                        this.msg = result.data.msg
                        this.wrongInput()
                    } else {
                        localStorage.setItem('token',result.data.data);
                        // this.$router.replace('/');  //这个跳转方式,无法浏览器后退
                        // this.$router.push('login')  //这个方式,可以后退,无法带参数
                        // this.$router.push({name:'home',query:{id:'2'}})  //这个方式通过index.js设置name后,可以后退,也可以带参数
                        // this.$router.push({ name: 'home', params: { id: '荷载' } })  //这个方式通过index.js设置name后,可以后退,也可以带参数
                        this.$router.push({ name: 'play' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
                        //  this.$router.push({name: 'home', query: {id: '1'});
                        // this.$router.push({name: 'home', params: {id: '1'}}); 
                        //query 和 params 的区别：
                        //           query 类似于get, 跳转页面之后, ur了参数后边会拼接参数, 
                        //           类似: id=1; 非常重要性这样可以传下去, 密码之类还是用params 刷新页面id 还是存在到 
                        //           但是params类似post 请求, 跳转页面之后, url不会拼接参数, 但是刷新页面id 会消失。
                        //html 取 $router.params.id
                        //  script: 脚本取参: $this.$router.params.id;

                    }
                })

            )


        },

    }

}



</script>

<style>
.box {
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: row;
    user-select: none;
}

.box>div {
    height: 100vh;
    flex: 1;

}

.left {
    background-image: url('../assets/bg.png');
    min-width: 70%;
    max-width: 70%;
}

.title {
    opacity: 1;
    font-size: 75px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 156.38px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    left: 5%;
    top: 10%;
    text-shadow: 0 0 15px #8812e8, 0 0 10px #727272;
    user-select: none;
}

.title1 {
    opacity: 1;
    font-size: 70px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 156.38px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    left: 25%;
    top: 23%;
    text-shadow: 0 0 15px #8812e8, 0 0 10px #727272;
    user-select: none;
}

.title2 {
    opacity: 1;
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 156.38px;
    color: rgba(255, 255, 255, 1);
    position: absolute;
    left:30%;
    top: 40%;
    text-shadow: 0 0 15px #8812e8, 0 0 10px #727272;
    user-select: none;
}

.login {
    opacity: 1;
    /** 文本1 */
    font-size: 50px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 200px;
    color: rgba(45, 197, 146, 1);

    position: relative;
    left: 40%;
    vertical-align: top;

}

.account {
    opacity: 1;

    font-size: 34px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 98.46px;
    color: rgba(45, 197, 146, 1);
    position: relative;
    left: 15%;
    top: 30%;
    user-select: none;

}
</style>

