
<!--  template 模板部分,由它生成HTML代码 -->
<template>
  <div id="app">
  <!-- <div id="app"> -->
    <!-- <nav>
      <h1>{{message}}</h1>
      <element-view></element-view>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- 下面导入的ElementView会被识别为element-view或ElementView -->
    <!-- <element-view></element-view> -->
    <!-- <ElementView></ElementView> -->
    <router-view/>
    <!-- <router-view v-slot="{ Component }">
      <transition name="slide" >
        <component :is="Component" />
      </transition>
    </router-view> -->
    
  </div>
</template>

<!-- script 控制模板的数据来源和行为 -->
<script>
// import ElementView导入组件,然后再components里注册
// import ElementView from './views/element/ElementView.vue'
export default {
  // components: { ElementView },
  data() {
    return{
      
    }
  }
}
</script>



<!-- style 这是css样式部分 -->
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding:0;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}


</style>
