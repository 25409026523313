<template>
    <div class="page-box">
        <div class="left-box">
            <img src="../assets/play.png"
                style="position:relative ;top:10%;max-width: 70%;height: auto;filter:brightness(150%); cursor:pointer">
            <img src="../assets/library.png" @click="toSongView"
                style="position:relative ;top:20%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/singer.png" @click="toSingerView"
                style="position:relative ; top:30%;max-width: 70%;height: auto; cursor:pointer">
            <img src="../assets/upload.png" @click="toUploadView"
                style="position:relative ; top:40%;max-width: 70%;height: auto; cursor:pointer">
        </div>

        <div class="middle-box">
            <img :src="imgUrl" style="position:absolute ; top:15%;left:27%; 
            border-radius:50%; width:45%;height:45%; z-index:2 ;user-select: none">
            <img src="../assets/white.png" style="position:relative ;width:70% ; height:70% ; top:10% ;
                filter:blur(15px); brightness(1.5);user-select: none;">
            <div class="container">
                <h2 style="position:relative;user-select: none;">{{ musicName }}</h2>

                <!--  :src="audioPathDic[musicName]"  -->
                <audio ref="Ref_audioPlayer" :src="songUrl" @ended="overAudio" @pause="onPause" @play="onPlay"
                    @loadeddata="loadeddata" @timeupdate="timeupdate" preload>
                </audio>
                <div class="listBox">
                    <div class="myPlayer">
                        <el-slider @mousedown="isDragIng = 'true'" @mouseup="isDragIng = 'false'"
                            @change="currentRateChange" v-model="currentRate" :marks="marks" :show-tooltip="false">
                        </el-slider>
                        <div class="controlBox">
                            <i @click="move(-5)" class="el-icon-d-arrow-left" style="cursor:pointer;"
                                onmouseout="this.style.color='';"
                                onmouseover="this.style.color='#19f0a8'; this.style.cursor='hand'"></i>
                            <i type="primary" v-if="playStatus" @click="pause" class="el-icon-video-pause"
                                style="cursor:pointer;" onmouseout="this.style.color='';"
                                onmouseover="this.style.color='#19f0a8'; this.style.cursor='hand'"></i>
                            <i type="primary" v-else @click="play" class="el-icon-video-play" style="cursor:pointer;"
                                onmouseout="this.style.color='';"
                                onmouseover="this.style.color='#19f0a8'; this.style.cursor='hand'"></i>
                            <i @click="replay" class="el-icon-refresh-left" style="cursor:pointer;"
                                onmouseout="this.style.color='';"
                                onmouseover="this.style.color='#19f0a8'; this.style.cursor='hand'"></i>
                            <i @click="move(5)" class="el-icon-d-arrow-right" style="cursor:pointer;"
                                onmouseout="this.style.color='';"
                                onmouseover="this.style.color='#19f0a8'; this.style.cursor='hand'"></i>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div>
            <!-- 设置id名date,后面的定时器方法中document.getElementById("date")可以把文本填入, 显示时间,文本不可选中 -->
            <p id="date" style="position:absolute; top:o; right:10% ; font-weight:bold ; user-select: none;"></p>
            <!-- 退出按钮,移入变样式 -->
            <p style="position:absolute; top:o; right:5% ; font-weight:bold ;cursor:pointer;user-select: none;" @click="exit"
                onmouseout="this.style.color='';" onmouseover="this.style.color='#ff0000'; this.style.cursor='hand'">退出</p>
        </div>
        <div class="right-box">

            <p style="
            user-select: none;
            font-size: 48px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 3px;
            color: rgba(255, 255, 255, 1);">「LIST」</p>
            <div class="right-box-inside">
                <br>
                <el-table :data="song" highlight-current-row @row-click="changeMusic" :show-header=false
                    style="width: 98%;user-select: none;" max-height="480" height="480">
                    <!-- 下面这行可以设置序号 -->
                    <el-table-column type="index" :index="indexMethod" >
                </el-table-column>
                    <el-table-column prop="sname" label="歌名" width="180">
                    </el-table-column>
                    <el-table-column prop="pname" label="歌手">
                    </el-table-column>
                    <el-table-column prop="time" label="时长">
                    </el-table-column>
                </el-table>
                <br>
                <el-pagination small hide-on-single-page layout="prev, pager, next" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :total="total"></el-pagination>
                <br>
            </div>

            <div class="btnBox" style="display:none">
                <el-button @click="getLength" type="primary" size="small">获取时长</el-button>
                <el-button @click="getCurrentTime" type="primary" size="small">获取播放进度</el-button>
                <el-button @click="getPlayStatus" type="primary" size="small">获取播放状态</el-button>
            </div>
        </div>


    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            playStatus: false,
            isDragIng: false,
            marks: {
                0: "",
                100: "",
            },
            currentRate: 0,
            msg: "待播放",
            // 歌曲名称
            song: [],
            musicName: "Music Box",

            songUrl: '',
            imgUrl: require('../assets/唱片.jpeg'),
            total: 0,
            timer: '',
            // 分页记录当前编号
            pagination: {
                currentPage: 1,  //当前页
                pageSize: 10  //每页显示多少条数据
            },

        };
    },
    methods: {

        // 监听audio标签的音频加载完成事件
        loadeddata() {
            // 需等音频加载完成，才能获取到音频的时长
            this.marks["100"] = this.s_to_MS(this.$refs.Ref_audioPlayer.duration);
        },

        // 监听audio标签的播放事件
        onPlay() {
            this.msg = "播放中";
            this.playStatus = true;
        },
        // 监听audio标签的暂停事件
        onPause() {
            this.msg = "已暂停";
            this.playStatus = false;
        },
        // 监听audio标签的停止播放事件
        overAudio() {
            this.msg = "播放结束";
            this.playStatus = false;
        },
        // 监听audio标签的播放进度改变事件
        timeupdate(e) {
            // 使用this.isDragIng来控制，当拖拽进度条的过程中，停止对进度的更新——用于解决bug:拖动进度条自动回弹
            if (!this.isDragIng) {
                this.currentRate = Math.floor(
                    (e.target.currentTime / this.$refs.Ref_audioPlayer.duration) * 100
                );

                this.marks["0"] = this.s_to_MS(e.target.currentTime);
            }
        },

        // 秒数转换分+秒
        s_to_MS(s) {
            let m;
            m = Math.floor(s / 60);
            s = Math.floor(s % 60);
            m += "";
            s += "";
            s = s.length == 1 ? "0" + s : s;
            if (isNaN(m)) {
                return "&infin;";
            }
            return m + ":" + s;
        },

        // 切换音乐
        changeMusic(row) {
            let temp = this.musicName;
            this.musicName = row.sname;
            try {
                this.songUrl = row.url;
                // this.songUrl = require("@/assets/" + row.sname + ".mp3");
                this.imgUrl = row.img;
                if (this.$refs.Ref_audioPlayer.networkState == 3) {
                    alert("加载错误");
                    this.imgUrl = require('../assets/唱片.jpeg')
                } else {
                    this.$nextTick(() => {
                        this.$refs.Ref_audioPlayer.play();
                    })
                }

            } catch (error) {
                alert("无法加载音乐");
                this.musicName = temp;
                this.$refs.Ref_audioPlayer.pause();
            }
        },

        // 原创面板——播放
        play() {
            if (this.$refs.Ref_audioPlayer.networkState == 3) {
                alert("加载失败")
            } else {
                console.log("测试:"+JSON.stringify(this.song[0]))
                this.$refs.Ref_audioPlayer.play();
            }

        },

        // 原创面板——暂停
        pause() {
            this.$refs.Ref_audioPlayer.pause();
        },

        // 原创面板——重新播放
        replay() {
            this.$refs.Ref_audioPlayer.currentTime = 0;
            this.$refs.Ref_audioPlayer.play();
        },

        // 原创面板——前进、后退——改变当前播放位置
        move(val) {
            this.$refs.Ref_audioPlayer.currentTime += val;
            this.$refs.Ref_audioPlayer.play();
        },

        // 原创面板——点击/拖拽原创面板进度条
        currentRateChange(newVal) {
            this.$refs.Ref_audioPlayer.currentTime = Math.round(
                (newVal / 100) * this.$refs.Ref_audioPlayer.duration
            );
            this.$refs.Ref_audioPlayer.play();
        },

        // 获取播放状态
        getPlayStatus() {
            this.$notify.info({
                title: "当前播放状态",
                message: this.$refs.Ref_audioPlayer.paused ? "已暂停" : "播放中",
            });
        },

        // 获取播放进度
        getCurrentTime() {
            // 已播放时长（单位秒s）
            let totalSeconds = this.$refs.Ref_audioPlayer.currentTime;
            // 分钟数
            let mins = Math.floor(totalSeconds / 60);
            // 秒数
            let seconds = Math.floor(totalSeconds % 60);

            this.$notify.info({
                title: "当前进度",
                message: `${mins}分${seconds}秒`,
            });
        },

        // 获取音频时长
        getLength() {
            let totalSeconds = this.$refs.Ref_audioPlayer.duration;
            // 分钟数
            let min = Math.floor(totalSeconds / 60);
            // 秒数
            let seconds = Math.floor(totalSeconds % 60);

            this.$notify.info({
                title: "时长",
                message: `${min}分${seconds}秒`,
            });
            // 时长（单位秒s）
        },

        // 表格序号
        indexMethod(index) {
            return index + (this.pagination.currentPage - 1) * this.pagination.pageSize + 1
        },


        onsubmit: function () {
            alert("查询数据");
        },
        handleSizeChange: function (val) {
            alert("每页记录数变化:" + val)
        },
        handleCurrentChange: function (val) {
            this.pagination.currentPage = val
            axios.get("https://music-box.top:8443/songs", {
                params: {
                    pageSize: 10,
                    page: val
                },
                headers: { Token: localStorage.getItem('token') }
            }).then(result => {
                this.song = result.data.data.rows
                this.total = result.data.data.total

            }).catch((error) => {
                // 请求失败处理逻辑
                localStorage.setItem("token", "");
                if (error.response.status == 401) {
                    this.$message(
                        {
                            showClose: true,
                            message: error.response.data.msg,
                            type: 'warning'
                        }
                    )
                } else {
                    this.$message(
                        {
                            showClose: true,
                            message: "请求错误",
                            type: 'warning'
                        }
                    )
                }
                this.$router.push({ name: 'login' })
            });
        },


        

        myDate() {
            var now = new Date();
            var year = now.getFullYear();
            var month = (now.getMonth() + 1) > 9 ? (now.getMonth() + 1) : "0" + (now.getMonth() + 1);
            var day = now.getDate() > 9 ? now.getDate() : "0" + now.getDate();
            var hours = now.getHours() > 9 ? now.getHours() : "0" + now.getHours();
            var minutes = now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes();
            var seconds = now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds();
            document.getElementById("date").innerHTML = year + "年" + month + "月" + day + "日" + hours + ":" + minutes + ":" + seconds;
        },

        exit() {
            localStorage.setItem("token", "");
            this.$router.push({ name: 'login' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toSongView() {
            this.$router.push({ name: 'song' })  //这个方式通过index.js设置name后,可以后退,也可以带参数

        },
        toSingerView() {
            this.$router.push({ name: 'singer' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },

        toUploadView() {
            this.$router.push({ name: 'upload' })  //这个方式通过index.js设置name后,可以后退,也可以带参数
        },




    },


    mounted() {
        axios.get("https://music-box.top:8443/songs", {
            headers: { Token: localStorage.getItem('token') }
        }).then(result => {
            this.song = result.data.data.rows
            this.total = result.data.data.total
            this.songUrl = this.song[0].url
        }).catch((error) => {
            // 请求失败处理逻辑
            // console.log(error.response.status)
            // 不能用"xx:" + 拼接这里的response的内容,不然输出不了
            // console.log(JSON.stringify(error.response.data))
            localStorage.setItem("token", "");
            if (error.response.status == 401) {
                this.$message(
                    {
                        showClose: true,
                        message: error.response.data.msg,
                        type: 'warning'
                    }
                )
            } else {
                this.$message(
                    {
                        showClose: true,
                        message: "请求错误",
                        type: 'warning'
                    }
                )
            }
            this.$router.push({ name: 'login' })
        });

        this.timer = setInterval(this.myDate, 100);


    },

    beforeDestroy() {
        clearInterval(this.timer);
    }


};





</script>

<style scoped  >
/*最外层透明*/
::v-deep .el-table,
::v-deep .el-table__expanded-cell {
    background-color: transparent;
    border-radius: 20px;
    margin-left: 1%;
    margin-right: 1%;
    border: none
}


/* 表格内背景颜色 */
::v-deep .el-table th {
    background-color: transparent;

}

::v-deep .el-table tr {
    background-color: transparent;

}

/* 表格数据背景透明,表格横线去掉*/
::v-deep .el-table td {
    background-color: transparent;
    border-bottom: none !important;

}

/* 去掉表格最下面一条线*/
::v-deep .el-table::before {

    height: 0;

}

/* 表格悬停的时候变色*/
::v-deep .el-table__body tr:hover>td {
    background-color: #0cdd6a !important;
    box-shadow: 1px -1px 50px 1px #6df876, inset 0px 0px 50px 1px #6df876;
}

/* 选中表格的颜色*/
::v-deep .el-table__body tr.current-row>td {
    color: #010603;
    background: #0cf073b8 !important;
}


/*  分页未选中按钮背景颜色*/
::v-deep .el-pagination .is-background {
    background-color: transparent;

}

/* 分页未选中的字体颜色 */
::v-deep .el-pagination .el-pager li:not(.disabled) {
    background-color: transparent;

    color: #04572d;
    font-size: 18px;
}

/*  分页选中按钮背景颜色和字体颜色 */
::v-deep.el-pagination .el-pager li:not(.disabled).active {
    background-color: transparent;
    color: #19f0a8;
    font-size: 17px;
}

/* 上一页和下一页背景颜色和字体颜色*/
::v-deep .el-pagination .btn-prev,
::v-deep .el-pagination .btn-next {
    background-color: rgba(99, 135, 206, 0);
    color: #04572d;
    transform: scale(1.5)
}

/* 滑动条颜色*/
::v-deep .el-slider__bar {
    background: #0fdf197b;
}

/* 滑轨颜色*/
::v-deep .el-slider__runway {
    background-color: #c5ebbd;
}

/* 滑块按钮颜色*/
::v-deep .el-slider__button {

    border: 3px solid #19f0a8;

}

/* slider的字颜色*/
::v-deep .el-slider__marks-text {
    color: #19f0a8;
}


.page-box {
    background-color: #26AE85;
    display: flex;
    height: 100vh;
}


.middle-box {
    position: absolute;
    left: 12%;
    top: 7%;
    height: 85vh;
    width: 50%;
    background-color: #1791734f;
    border-radius: 45px;
    box-shadow: 2px 2px 10px 2px #0e6650, inset 2px 2px 10px 2px #0e6650;


}


.right-box {
    position: absolute;
    right: 2%;
    top: 7%;
    height: 85%;
    width: 33%;
    background: #b4ea1f00;
    user-select: none;

}

.right-box-inside {

    border-radius: 45px;
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
}

.left-box {
    height: 85%;
    width: 7%;
    position: relative;
    top: 7%;
    left: 2%;
    user-select: none;
    /* box-shadow: inset 是设为内阴影,默认是外阴影,剩下五个值,分别为水平,竖直,模糊效果,阴影半径,颜色 */
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
    /* 高斯模糊*/
    border-radius: 45px;
    /* 圆角*/

    overflow-y: auto;



}

.left-box:hover {
    transition: all 2s;
    /*旋转时间 */
    transform: scale(1.05);
}

.left-box>img:hover {
    transition: all 1.5s;
    /*旋转时间 */
    transform: scale(1.2);
    /* 放大1.1倍,旋转360度*/
    filter: brightness(1.2);
    /* 提升亮度 */
}


.btnBox {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-content: space-around;
}

.container {
    text-align: center;
    max-width: 500px;
    margin: auto;
}

.listBox {
    text-align: left;
    position: relative;
    bottom: 20px;

}

.subTitle {
    margin-left: 40px;
}

.musicItem {
    line-height: 40px;
    cursor: pointer;
}

.myPlayer {

    border: 4px solid #0e6650;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 45px;
    box-shadow: inset 2px 2px 10px 2px #0e6650, 2px 2px 10px 2px #0e6650;
    user-select: none;

}

.controlBox {
    display: flex;
    justify-content: space-evenly;
    font-size: 40px;
    color: #055c09;
    opacity: 0.8;


}

/* 去除手机网页点击事件的阴影 */
* {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}
</style>



